<template>
  <SetLoader v-if="!formShow && responseError.length == 0" />
  <AlertBox v-if="!formShow" type="warning" :messages="responseError" />
  <form v-if="formShow" class="form w-100" id="editForm">
    <HiddenFields crudType="Edit" :pageData="pageData" />
    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <AlertBox type="warning" :messages="responseError" />
          <ValidationMessageList
            v-if="updateResponseValidationModel.showValidations"
            :msg="updateResponseValidationModel.msg"
            :message="updateResponseValidationModel.message"
            :errors="updateResponseValidationModel.errors"
            :warnings="updateResponseValidationModel.warnings"
            :informations="updateResponseValidationModel.informations"
          />
          <ValidationMessageListInModal
            :windowOpener="windowOpener"
            :errors="updateResponseValidationModel.modalErrors"
            :warnings="updateResponseValidationModel.modalWarnings"
            :informations="updateResponseValidationModel.modalInformations"
            :businessRuleErrors="
              updateResponseValidationModel.businessRuleErrors
            "
          />
        </div>
      </div>
    </div>
    <DesignItem
      v-for="element in designedItems"
      :key="element.id"
      :element="element"
      :items="designedItems"
    />
    <ActionButtons
      @methodEdit="onRecordEdit('btn-edit')"
      :isToolbar="false"
      :classes="{
        'ps-0 p-2 hidden': !$isMobile(),
        hidden: $isMobile(),
      }"
    />
  </form>
</template>
<script>
import HiddenFields from "@/components/set-design/form-items/HiddenFields.vue";
import { createToast } from "mosha-vue-toastify";
import $ from "jquery";
import DesignItem from "@/components/set-design/DesignItem.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ValidationMessageList from "@/components/custom/validation-message/List.vue";
import ValidationMessageListInModal from "@/components/custom/validation-message/Modal.vue";
import { showModal } from "@/core/helpers/dom";
export default {
  name: "EDIT",
  props: ["key", "id", "pageLayoutId"],
  data() {
    return {
      submitButtonHtml: String.format(
        '<span class="loading-block"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span> {0}</span></span>',
        this.$t(
          "BaseModelFields.PleaseWait",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
      disabledButtonClasses: ".btn-edit, .back-to-detail",
      pageData: {},
      designedItems: [],
      formPosting: false,
      formShow: false,
      isSavedData: false,
      responseError: [],
      model: {},
      updateResponseValidationModel: {},
      windowOpener: window.opener,
    };
  },
  components: {
    DesignItem,
    HiddenFields,
    ValidationMessageList,
    ValidationMessageListInModal,
  },
  watch: {
    "$route.params.key"() {
      if (this.$route.name === "edit" && this.formShow) {
        this.getEditPage();
      }
    },
    "$route.params.id"() {
      if (this.$route.name === "edit" && this.formShow) {
        this.getEditPage();
      }
    },
    "$route.query.pageLayoutId"() {
      if (this.$route.name === "edit" && this.formShow) {
        this.getEditPage();
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    if (!this.isSavedData && this.$root.checkUnSavedData("#editForm")) {
      const answer = window.confirm(
        this.$t(
          "BaseModelFields.AlertUnSavedData",
          {},
          { locale: this.$store.state.activeLang }
        )
      );
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  beforeRouteUpdate(to, from, next) {
    if (!this.isSavedData && this.$root.checkUnSavedData("#editForm")) {
      const answer = window.confirm(
        this.$t(
          "BaseModelFields.AlertUnSavedData",
          {},
          { locale: this.$store.state.activeLang }
        )
      );
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  mounted() {
    this.getEditPage();
  },
  methods: {
    getEditPage() {
      setCurrentPageBreadcrumbs("", [{ name: "HELP" }]);
      this.designedItems = [];
      this.responseError = [];
      this.updateResponseValidationModel = {};
      this.formShow = false;
      this.$root.getSetScripts(this.$route.params.key, "Edit").then(() => {
        this.pageProcess();
      });
    },
    pageProcess() {
      var key = this.$route.params.key,
        recordPublicId = this.$route.params.id,
        pageLayoutId = this.$route.query.pageLayoutId;

      if (
        String.isNullOrWhiteSpace(key) ||
        String.isNullOrWhiteSpace(recordPublicId) ||
        (!String.isNullOrWhiteSpace(recordPublicId) &&
          !String.isGuid(recordPublicId))
      ) {
        this.$router.push(this.$appHomeUrl);
        return;
      }

      var editPageRequestUrl = `rws-SetController-EditPage?key=${key}&id=${recordPublicId}`;
      if (!String.isNullOrWhiteSpace(pageLayoutId)) {
        editPageRequestUrl += `&pageLayoutId=${pageLayoutId}`;
      }

      this.$appAxios
        .get(editPageRequestUrl)
        .then((response) => {
          this.pageData = response.data;
          this.$store.commit("setPageDto", this.pageData);

          if (this.pageData.isOk) {
            if (
              String.isNullOrWhiteSpace(this.pageData.layout) ||
              String.isNullOrWhiteSpace(this.pageData.layout.layout)
            ) {
              this.$store.commit("setPageLayoutAvailable", false);
              this.$router.push({
                name: "PageLayoutNone",
                params: { key: this.$route.params.key },
              });
              return;
            }

            this.formShow = true;
            if (this.pageData.layout.layout) {
              this.$root.operationAfterPageLoadForSetScripts();
              this.$store.commit("setPageLayoutAvailable", true);
              this.designedItems = JSON.parse(this.pageData.layout.layout);
            }

            var customObjectName = this.pageData.name,
              customObjectPluralName = this.pageData.customObjectPluralName;
            if (this.$store.state.isMultiLanguage) {
              var localizationValue =
                this.$store.getters.getLocalizationValuesByParameters({
                  itemFormulaName: this.pageData.layout.customObjectFormulaName,
                  itemTypeId: 1,
                });
              if (localizationValue) {
                customObjectName = localizationValue.value;
              }

              var localizationValueForPluralName =
                this.$store.getters.getLocalizationValuesByParameters({
                  itemFormulaName: this.pageData.layout.customObjectFormulaName,
                  itemTypeId: 11,
                });
              if (localizationValueForPluralName) {
                customObjectPluralName = localizationValueForPluralName.value;
              }
            }

            var title = this.$t(
              "Set.EditFormat",
              {},
              { locale: this.$store.state.activeLang }
            ).replaceAll("OBJECT_NAME", customObjectName);

            this.$root.changeDocumentTitle(title);
            setCurrentPageBreadcrumbs(title, [
              {
                name: customObjectPluralName,
                isRouteLink: true,
                html: `/set/list/${this.$route.params.key}`,
              },
              {
                name: title,
              },
            ]);
          } else {
            this.$root
              .validationProcess(this.pageData.validations)
              .then((redirected) => {
                if (!redirected) {
                  this.$root.changeDocumentTitle(
                    this.$t("Components.LostWave.Title"),
                    {},
                    { locale: this.$store.state.activeLang }
                  );
                  setCurrentPageBreadcrumbs(
                    this.$t(
                      "Components.LostWave.Title",
                      {},
                      { locale: this.$store.state.activeLang }
                    ),
                    []
                  );
                  this.responseError.push(response.data.msg);
                } else {
                  this.formShow = true;
                }
              });
          }
        })
        .catch(function (error) {
          //TODO:Error
          // alert(error);
        });
    },
    gotoListPage() {
      this.$router.push({
        name: "list",
        params: {
          key: this.$route.params.key,
        },
      });
    },
    onRecordEdit(buttonName, isForcingSave = false) {
      var form = $("form#editForm"),
        self = this,
        model = this.$root.modelCreate("form#editForm"),
        allFields = this.pageData.fields.find(
          (f) => f.isMainObject
        ).fieldModels;

      this.updateResponseValidationModel = {
        showValidations: false,
        msg: "",
        message: "",
        errors: [],
        modalErrors: [],
        warnings: [],
        modalWarnings: [],
        informations: [],
        modalInformations: [],
        businessRuleErrors: [],
      };

      if (!form[0].checkValidity() || !this.$root.forceFormValidation(form)) {
        form.addClass("was-validated");

        this.$root.elementScrollToTop($("html, body"));
        this.updateResponseValidationModel.showValidations = true;
        this.updateResponseValidationModel.errors = this.$root.formValidation(
          form,
          model.values,
          allFields
        );

        return;
      }

      var clonedModel = this.$root.modelCreate("form#editForm");
      this.model = model;

      //changed items filtering and mapping
      var changedFieldValues = [];
      model.values.forEach((fieldValue) => {
        //TODO: no trim !!
        var isChangedValue = fieldValue.value !== fieldValue.oldValue,
          isSelectlistItem = self.$root.fieldTypes.select.includes(
            fieldValue.fieldType
          ),
          textOldTextNotNull =
            !String.isNullOrWhiteSpace(fieldValue.text) &&
            !String.isNullOrWhiteSpace(fieldValue.oldText);
        if (
          fieldValue.fieldType.includes("Phone") &&
          fieldValue.value.trim().replaceAll(" ", "") !==
            fieldValue.oldValue.trim().replaceAll(" ", "")
        ) {
          changedFieldValues.push(fieldValue);
        } else {
          if (isSelectlistItem) {
            if (isChangedValue) {
              changedFieldValues.push(fieldValue);
            } else if (
              textOldTextNotNull &&
              fieldValue.text !== fieldValue.oldText
            ) {
              changedFieldValues.push(fieldValue);
            }
          } else if (isChangedValue) {
            changedFieldValues.push(fieldValue);
          }
        }
      });

      this.model.values = changedFieldValues.map(function (m) {
        return { key: m.key, value: m.value };
      });

      var onlyLiveLookupValues = clonedModel.values.filter(
        (f) => f.isLiveLookup
      );
      if (onlyLiveLookupValues.length > 0) {
        onlyLiveLookupValues.forEach((liveLookupValueObj) => {
          if (
            !this.model.values.find((f) => f.key === liveLookupValueObj.key)
          ) {
            this.model.values.push({
              key: liveLookupValueObj.key,
              value: liveLookupValueObj.value,
            });
          }
        });
      }

      var button = $(`.${buttonName}`),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block"),
        disabledButtons = $(this.disabledButtonClasses);

      disabledButtons.prop("disabled", true);
      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.submitButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }

      this.model.isForcingSave = isForcingSave;

      this.$appAxios
        .post("/rws-SetController-Edit", this.model)
        .then((response) => {
          var result = response.data,
            brErrors = result.businessRuleResponse.businessRuleResponses;

          if (!this.windowOpener) {
            this.$store.commit("setPagesCRUDResult", result);
          }

          if (result.isOk) {
            self.isSavedData = true;
            createToast(
              this.$t(
                "BaseModelFields.SuccessMessage",
                {},
                { locale: this.$store.state.activeLang }
              ),
              {
                showIcon: true,
                position: "top-right",
                type: "success",
                transition: "zoom",
              }
            );

            self.$router.push({
              name: "detail",
              params: {
                key: this.$route.params.key,
                id: result.recordPublicId,
              },
              query: {
                pageLayoutId: this.$route.query.pageLayoutId,
              },
            });

            this.$root.createEvent("SX_EDIT_RECORD_UPDATED", {
              title: "Edit Record Updated",
              message: "Event triggered when edit record updated",
              result: result,
              model: this.model,
            });
          } else {
            this.$root.elementScrollToTop($("html, body"));
            this.updateResponseValidationModel = {
              msg: result.msg,
              message: result.message,
              errors: this.$root.getValidationMessages(
                result.validations,
                false,
                1,
                allFields
              ),
              modalErrors: this.$root.getValidationMessages(
                result.validations,
                true,
                1,
                allFields
              ),
              warnings: this.$root.getValidationMessages(
                result.validations,
                false,
                2,
                allFields
              ),
              modalWarnings: this.$root.getValidationMessages(
                result.validations,
                true,
                2,
                allFields
              ),
              informations: this.$root.getValidationMessages(
                result.validations,
                false,
                3,
                allFields
              ),
              modalInformations: this.$root.getValidationMessages(
                result.validations,
                true,
                3,
                allFields
              ),
              businessRuleErrors: brErrors,
            };

            //unknown error
            if (
              String.isNullOrWhiteSpace(
                this.updateResponseValidationModel.msg
              ) &&
              String.isNullOrWhiteSpace(
                this.updateResponseValidationModel.message
              ) &&
              this.updateResponseValidationModel.errors.length == 0 &&
              this.updateResponseValidationModel.warnings.length == 0 &&
              this.updateResponseValidationModel.informations.length == 0 &&
              this.updateResponseValidationModel.modalErrors.length == 0 &&
              this.updateResponseValidationModel.modalWarnings.length == 0 &&
              this.updateResponseValidationModel.modalInformations.length == 0
            ) {
              this.updateResponseValidationModel.msg = this.$t(
                "BaseModelFields.AnUnknownErrorHasOccurred",
                {},
                { locale: this.$store.state.activeLang }
              );
            }

            this.updateResponseValidationModel.showValidations =
              !String.isNullOrWhiteSpace(
                this.updateResponseValidationModel.msg
              ) ||
              !String.isNullOrWhiteSpace(
                this.updateResponseValidationModel.message
              ) ||
              this.updateResponseValidationModel.errors.length > 0 ||
              this.updateResponseValidationModel.warnings.length > 0 ||
              this.updateResponseValidationModel.informations.length > 0;

            firstSpan.show();
            loadingBlock.hide();
            disabledButtons.prop("disabled", false);
          }

          var modalShow =
            this.updateResponseValidationModel.businessRuleErrors.length > 0 ||
            this.updateResponseValidationModel.modalErrors.length > 0 ||
            this.updateResponseValidationModel.modalWarnings.length > 0 ||
            this.updateResponseValidationModel.modalInformations.length > 0;
          if (modalShow) {
            showModal(document.getElementById("modalValidationMessage"));
          }
        })
        .catch(function (error) {
          // alert(error);
          firstSpan.show();
          loadingBlock.hide();
          disabledButtons.prop("disabled", false);
        });
    },
  },
};
</script>
